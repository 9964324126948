import interiorImage from '../images/zeekle-section4.png'

const Interior = () => {
    return ( 
        <section className="container-fluid interior">
            <div className="interior-group container">
                <div className="interior-text">
                    <p>With concepts and styles which will captivate and inspire , Zeekle Arcadia delivers all the essentials-and-more while giving you the space to make your mark on your new home.
                        Aesthetically pleasing, secure and exemplifying what modern living is all about. Zeekle Arcadia is an address that is waiting to be made a home. </p> 
                    <p>Beautifully Appointed Interiors</p>
                    <ul>
                        <li>Stylish fabricated designed kitchen with an extensive range of high quality fittings and work surfaces</li>
                        <li>Integrated appliance - Microwave , Concealed sound , security door</li>
                        <li>Modern flooring</li>
                        <li>High quality internal doors</li>
                        <li>Quality electrical fittings</li>
                        <li>High spec decking on all terrace areas with designer lightnings</li>
                    </ul>
                    <p>Bathrooms and Ensuites</p>
                    <ul>
                        <li>Concealed cistern WC and luxury basins</li>
                        <li>Walk in shower enclosure</li>
                        <li>High quality showed trays</li>
                    </ul>
                </div> 
                <div className="interior-image-container">
                <img src={interiorImage} alt="interior images" className="interior-image" />
                </div>
            </div>
        </section>
     );
}
 
export default Interior;