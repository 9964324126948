import aboutImage from '../images/zeekle-section2.jpg'

const AboutZeekle = () => {
    return ( 
        <section className="container-fluid">
            <div className='container'>
            <div className="about">
                <div className="about-text-group">
                    <p className="about-text">
                    Zeekle Arcadia offers unique and distinctive accommodation,
                    in which light and space are key features.
                    </p>
                    <p className="about-text">
                    Its contemporary decorative feel together with its glass -
                    structures allows light to pour into the apartment giving an elevated , overwhelming sense of space.
                    </p>
                    <p className="about-text">
                    Sitting in the sublime quiet part of Ikate , Lekki within a few minutes from House on The Rock,
                    these buildings will be perfectly located to enjoy all that Lekki has to offer while ensuring
                    privacy and the very best in luxurious modern living even in the vibrant Lekki.
                    </p>
                    <a href='https://drive.google.com/file/d/1hk_gv92IhGv7Vnmex4s9cM7NhQj83lKD/view?usp=sharing' className='header-button'> REQUEST BROCHURE</a>
                </div>
                <div>
                    <img src={ aboutImage } alt="" className="about-image" />
                </div>
            </div>
            </div>
        </section>
     );
}
 
export default AboutZeekle;