import amenityImage from '../images/zeekle-section3.png'

const Amenities = () => {
    return ( 
        <section className = "container-fluid amenities-group">
            <div className='container'>
            <div className = "amen-group">
                <img src = { amenityImage } alt="estate amenities" className="amen-image" />
                <div className='amen-item'> 
                    <p className='amen-item-text'>
                        WE'RE NOT AFRAID TO DO SOMETHING DIFFERENT WITH OUR DEVELOPMENTS AND ZEEKLE ARCADIA REPRESENTS AN INNOVATIVE HOME FOR THE MODERN DWELLER
                    </p>
                    <a href='https://drive.google.com/file/d/1hk_gv92IhGv7Vnmex4s9cM7NhQj83lKD/view?usp=sharing' className='header-button'> REQUEST BROCHURE</a>
                </div>
            </div>
            </div>
        </section>
     );
}
 
export default Amenities;