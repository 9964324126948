import React, {useState, useRef} from "react";

const Form = () => {

    const formRef = useRef(null)
    const scriptURL = 'https://script.google.com/macros/s/AKfycbx-uOBHJQX9PrERmCOPvKXJH9LrjzGf376czJXAS5pcKY4zXWPjbLLh3avP0Ge9mKE/exec'
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [formClass, setFormClass] = useState('')

// submit event
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptURL, {
            method: 'POST',
            body: new FormData(formRef.current),
        }).then(res => {
            setMessage('SENT SUCCESSFULLY')
            setFormClass('success-message')

            setTimeout(() => {
                setFormClass('')
                setMessage('')
                }, 5000);

            setLoading(false)
            formRef.current.reset()
        })

}
    return ( 
        <div className="container-fluid form-section">
            <div className="container form-parent" >
                <h2 className="form-title">Reserve Apartment</h2>
            <form name="submit-to-google-sheet" ref={ formRef } onSubmit={handleSubmit}>
            <label>
                Full Name:
                <input type="text" name="name" className="full-name" required />
            </label>
            <label>
                Email Address:
                <input type="email" name="email" className="email-ad" required />
            </label>
            <label>
                Phone Number:
                <input type="tel" name="phone" className="phone" required />
            </label>
            <select className="select-text" name='select-apartment'>
                <option value="3bed">3 Bedroom Terrace</option>
                <option value="4bed">4 Bedroom Terrace</option>
            </select>
  
            <input type="submit" className="form-submit" value={loading ? "Sending..." : "Send Message"} />
            </form>
            <p className={ formClass }> { message } </p>
            </div>
        </div>
    );
}

 
export default Form;