import mainLogo from '../images/zeekle-main-logo.png'

const Navbar = () => {
    return (  
        <nav className='container-fluid'>
        <img src={mainLogo} alt='zeekle arcadia main logo' />
        </nav>
    );
}
 
export default Navbar;