import headerImage from '../images/zeekle-header.jpg'

const Header = () => {
    return ( 
        <header className='container-fluid'>
            <div className='container'>
            <div className='header-container'>
                <div className='header-card'>
                    <h1 className='header-title'>ZEEKLE ARCADIA</h1>
                    <p className='header-text'>A COLLECTION OF HIGH SPECIFICATION FENCED TERRACES</p>
                    <a href='https://drive.google.com/file/d/1hk_gv92IhGv7Vnmex4s9cM7NhQj83lKD/view?usp=sharing' className='header-button'> REQUEST BROCHURE</a>
                </div>
                <img src={headerImage} className='header-image' alt='zeekle header' />
            </div>
            </div>
        </header>

     );
}
 
export default Header;