import Carousel from 'react-bootstrap/Carousel';
import image1 from '../images/zeekle-image1.jpg';
import image2 from '../images/zeekle-image2.jpg';
import image3 from '../images/zeekle-image3.jpg';
import image4 from '../images/zeekle-image4.jpg';

const Gallery = () => {
    return ( 
        <section className="container-fluid gallery">
            <div className="container">
            <Carousel fade>
                <Carousel.Item>
                    <img src={ image1 } alt='' className='gallery-item' /> 
                    <Carousel.Caption>
                    <h3>Zeekle Arcadia 3D</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img src={ image2 } alt='' className='gallery-item' /> 
                    <Carousel.Caption>
                    <h3>Zeekle Arcadia 3D</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img src={ image3 } alt='' className='gallery-item' /> 
                    <Carousel.Caption>
                    <h3>Zeekle Arcadia Living Room</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img src={ image4 } alt='' className='gallery-item' /> 
                    <Carousel.Caption>
                    <h3>Zeekle Arcadia Bed Room</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel>
            </div>
        </section>
     );
}
 
export default Gallery;