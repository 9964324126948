const Footer = () => {
    const date = new Date()
    const thisYear = date.getFullYear()

    return (
        <footer className="container-fluid">
            <div className="footer-right">
                All rights reserved © {thisYear} <a href="https://thinkmint.ng" className="footer-link">Thinkmint Nigeria</a>
            </div>
        </footer>
     );
}
 
export default Footer;