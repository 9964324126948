import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutZeekle from './components/About';
import Amenities from './components/Amenities';
import Interior from './components/Interior';
import Gallery from './components/Gallery';
import Pricing from './components/Pricing';
import Form from './components/Form';
import AboutDeveloper from './components/AboutDeveloper';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <AboutZeekle />
      <Amenities />
      <Interior />
      <Gallery />
      <Pricing />
      <Form />
      <AboutDeveloper />
      <Footer />
    </div>
  );
}

export default App;
