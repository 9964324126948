import eaglelineLogo from '../images/eagleline-logo.png'

const AboutDeveloper = () => {
    return ( 
        <section className="container-fluid">
            <div className="container">
                <div className="about-developer-group">
                    <div className="about-developer-item">
                        <img src={eaglelineLogo} alt='eagle line logo' className='developer-logo' />
                        <p className='about-developer-text'>
                        ZEEKLE Arcadia is a development by <a href='https://www.eagleline.group/' className='developer-link'> EagleLine Consult </a> in conjunction with Bayrise construction and marketed by Thinkmint Nigeria. EagleLine Consult is a real estate development firm with mastery in property development , renovations and interior design.
                        </p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default AboutDeveloper;