const Pricing = () => {
    return ( 
        <section className="container-fluid pricing" >
            <div className="container">
                <div className="pricing-group">

                    <div className="pricing-item">
                        <h3 className='pricing-title'> 3 Bedroom Semi-Detached Duplex + BQ</h3>
                        <p className='pricing-text'>Price - &#8358;150,000,000</p>
                    </div>

                    <div className="pricing-item">
                        <h3 className='pricing-title'> 4 Bedroom Semi-Detached Duplex + BQ</h3>
                        <p className='pricing-text'>Price - &#8358;180,000,000</p>
                    </div>
                </div>
            
                <div className="documentation"> Documentation and Survey - &#8358;2,000,000</div>
                <div className="connection"> Connection Fee - &#8358;5,000,000</div>

            </div>
        </section>
     );
}
 
export default Pricing;